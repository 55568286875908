import React, { useState, useEffect, useRef } from "react";
import Tabs from "../Tabs";
import { useRouter } from "next/router";
import Link from "next/link";
import toast from "react-hot-toast";

const SearchBar = ({ main }) => {

	const router = useRouter()
	const tld = [
		'.cryptoworld',
		'.investor',
		'.kingdom',
		'.brandname',
		'.coinz'
	]
	const sld = [
		'1.gamerpro',
		'Home.brand',
		'Nft.store',
		'Mikej.cryptobull',
		'Best.domain',
	]
	const [placeholderIndex, setPlaceholderIndex] = useState(0);
	const [typingIndex, setTypingIndex] = useState(0);
	const [typingText, setTypingText] = useState("");
	const [activeTab, setActiveTab] = useState('TLD');

	const [domain, setDomain] = useState('');
	const [error, setError] = useState('');
	const searchRef = useRef()




	useEffect(() => {

		const currentPlaceholder = activeTab === 'TLD' ? tld[placeholderIndex] : sld[placeholderIndex]
		let currentIndex = 0;

		const typingTimer = setInterval(() => {
			if (currentIndex <= currentPlaceholder.length) {
				setTypingText(currentPlaceholder.slice(0, currentIndex));
				currentIndex++;
			} else {
				clearInterval(typingTimer);
				setTimeout(() => {
					setTypingIndex(0);
					setTypingText("");
					setPlaceholderIndex((prevIndex) =>
						(prevIndex + 1) % (activeTab === "TLD" ? tld.length : sld.length)
					);
				}, 2000);
			}
		}, 100);

		return () => {
			clearInterval(typingTimer);
		};
	}, [placeholderIndex]);

	const handleEnterKeyPress = (e) => {
		if (e.key === 'Enter') {

			e.preventDefault();
			if (validateDomain(domain)) {
				router.push(`/domains?q=${domain}`)
			}
		}
	};

	const handleChange = (e) => {
		const input = e.target.value;

		setDomain(input);

	};


	const validateDomain = (input) => {

		const domainParts = input.split(".");

		// Regex supports:
		// - Full emoji domains
		// - Standard alphanumeric domains with optional hyphens and dots
		// - Any combination of alphanumeric and emoji characters in domain and TLD
		// - Single TLDs (e.g., ".xyz"), single alphanumeric labels (e.g., "xyz"), and single emojis
		const domainRegex = /^(\.?[\w\-\u{1F300}-\u{1FFFF}]+)(\.([\w\-\u{1F300}-\u{1FFFF}]+))*$/u;


		// Check if input contains forbidden special characters
		const specialCharacterCheck = /^[a-zA-Z0-9\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}.\-]*$/u;

		if (!specialCharacterCheck.test(input)) {
			toast.error("Domain should not contain special characters like /, $, %, ^ etc.")

			return false;
		} else if (!domainRegex.test(input) || /\.{2,}/.test(input)) {
			toast.error("Please enter a valid domain name without subdomains (e.g., 'example.com').")

			return false;
		} else if (input.endsWith('.') || domainParts.length > 2) {
			toast.error("Complex domains are not supported. Please enter a basic SLD.TLD format.")
			return false
		}

		return true;
	};
	return (
		<div className="pb-4 relative" id="search-bar">
			<div className="flex justify-between items-center">
				{/* <div /> */}
				{/* <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={['TLD', 'SLD']} />
				 */}
				<div />
				{/* <Link href='/ai-domains' className="ai-btn px-2 mb-4 rounded-md font-semibold">Try AI Domains</Link> */}

			</div>
			<div className={`relative gradient-box dark:bg-jacarta-900 bg-white border-2  rounded-lg flex items-stretch ${!main && 'z-[1]'}`}>
				<div className={`flex items-center w-full  ${main ? 'm-2' : 'm-0'} `}>
					<input
						type="text"
						onKeyDown={handleEnterKeyPress} onChange={handleChange}
						value={domain}
						placeholder={'Type the domain you want'}
						className="!outline-none p-4  text-jacarta-800 dark:text-white text-lg dark border-0 rounded-l-md  dark:bg-jacarta-700 bg-jacarta-100 focus:!border-transparent focus:!outline-transparent border-transparent focus:ring-0 dark:placeholder-white/70 placeholder-gray-600 w-full placeholder:text-sm md:placeholder:text-lg"
					/>
					<button onClick={() => {
						if (validateDomain(domain)) {
							router.push(`/domains?q=${domain}`)
						}
					}} className="px-8 font-bold py-4 bg-blue-500 text-white bg-gray-300 dark:bg-jacarta-800 h-full rounded-r-[5px] hover:bg-jacarta-base transition-all duration-200 ">
						Search
					</button>
				</div>
			</div>
		</div>

	);
};

export default SearchBar;

