import React, { useState } from 'react';

const Tabs = ({ activeTab, setActiveTab }) => {

	const tabs = [
		'TLD',
		// 'SLD',
	];


	const handleTabClick = (tab) => {
		// console.log('Tab clicked:', tab);
		setActiveTab(tab);
	};

	return (
		<div className=" mb-4 flex-1">
			<div className="max-w-[200px] ">
				<div className="flex border-b border-gray-600 dark:border-gray-700">
					{tabs.map((tab, index) => (
						<button
							key={index}
							className={` flex-1 py-2 px-4 text-center transition-all duration-200 group   font-semibold  ${index === 0 ? 'rounded-l-md' : ''} ${index === tabs.length - 1 ? 'rounded-r-md' : ''} ${activeTab === tab ?
								'bg-gradient-to-tr from-hotPink via-purplish to-accent' : 'border-transparent text-jacarta-800   hover:bg-accent/20 dark:text-white '
								}`}
							onClick={() => handleTabClick(tab)}
						>
							{tab}
						</button>
					))}
				</div>
			</div>
		</div>
	);
};

export default Tabs;
