import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  domainCheck: null,
  domainCheckLoading: false,
};

export const discoverDomain = createSlice({
  name: "discoverDomain",
  initialState,
  reducers: {
    setDomainCheckResponse: (state) => {
      const { payload } = action;
      state.domainCheck = payload;
    },
    setDomainCheckLoading: (state) => {
      state.domainCheckLoading = !state.domainCheckLoading;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDomainCheckResponse, setDomainCheckLoading } =
  discoverDomain.actions;

export default discoverDomain.reducer;
