'use client';

import { useEffect, useState } from 'react';
import { 
  useAutoConnect, 
  useActiveAccount, 
  useDisconnect,
  useIsAutoConnecting 
} from "thirdweb/react";
import { createThirdwebClient } from "thirdweb";
import { useRouter, usePathname } from 'next/navigation';
import { useAuthRedirect } from '../hooks/useAuthRedirect';
import Cookies from 'js-cookie';
import Loading from './Loading';
import { useWalletDisconnect } from '../hooks/useWalletDisconnect';

const client = createThirdwebClient({
  clientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID,
});

export function AutoConnectProvider({ children }) {
  const router = useRouter();
  const pathname = usePathname();
  const account = useActiveAccount();
  const {handleDisconnect} = useWalletDisconnect();
  const isAutoConnecting = useIsAutoConnecting();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { redirectAfterAuth, getRedirectPath } = useAuthRedirect();

  const isDashboardRoute = pathname?.startsWith('/dashboard');
  const isSignInRoute = pathname === '/signin';

  const { data: autoConnected, isLoading } = useAutoConnect({
    client,
    enabled: isDashboardRoute || isSignInRoute,
    onConnect: async (connectedAccount) => {
      if (connectedAccount) {
        Cookies.set('walletAddress', connectedAccount.address, {
          expires: 7,
          secure: process.env.NODE_ENV === 'production',
          sameSite: 'strict'
        });
      }
    },
    onError: (error) => {
      console.error('Auto-connect error:', error);
      Cookies.remove('walletAddress');
    },
    timeout: 5000,
  });

  // Handle initial load completion
  useEffect(() => {
    if (!isAutoConnecting && !isLoading) {
      setIsInitialLoad(false);
    }
  }, [isAutoConnecting, isLoading]);

  useEffect(() => {
    // Skip during initial load
    if (isInitialLoad) return;

    const handleConnectionChange = async () => {
      if (account) {
        Cookies.set('walletAddress', account.address, {
          expires: 7,
          secure: process.env.NODE_ENV === 'production',
          sameSite: 'strict'
        });
        
        if (isSignInRoute) {
          // Use redirectAfterAuth instead of hardcoded path
          const redirectPath = getRedirectPath();
          if (redirectPath) {
            await redirectAfterAuth();
          } else {
            router.push('/dashboard');
          }
        }
      } else {
        const walletCookie = Cookies.get('walletAddress');
        if (walletCookie && !isAutoConnecting) {
          Cookies.remove('walletAddress');
          await handleDisconnect()
          
          if (isDashboardRoute) {
            // Store current path before redirecting to signin
            router.push(`/signin?redirect=${encodeURIComponent(pathname)}`);
          }
        }
      }
    };

    handleConnectionChange();
  }, [
    account, 
    router, 
    pathname, 
    handleDisconnect, 
    isInitialLoad, 
    isDashboardRoute, 
    isSignInRoute, 
    isAutoConnecting, 
    redirectAfterAuth,
    getRedirectPath
  ]);

  // Show loading only during initial load on dashboard routes
  if ((isInitialLoad || isAutoConnecting) && isDashboardRoute) {
    return <div className='relative h-[80vh]'><Loading size={24} /></div>;
  }

  return children;
}