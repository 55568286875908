import React, { useState, useEffect } from "react";
import { Menu } from "@headlessui/react";
import {  useWalletDetailsModal } from "thirdweb/react";
import Image from "next/image";
import Link from "next/link";
import {
  FaAngleDown,
  FaUser,
  FaDollarSign,
  FaRegUser,
} from "react-icons/fa";
import { BsReceipt } from "react-icons/bs";
import {  MdLogout } from "react-icons/md";
import DarkMode from "../mode/DarkMode";
import { LuLayoutDashboard } from "react-icons/lu";
import useProfile from "../../hooks/queryHooks/getUser";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { IoCopyOutline } from "react-icons/io5";
import { FiSun } from "react-icons/fi";
import { useTheme } from "next-themes";
import { polygon } from "thirdweb/chains";
import { createThirdwebClient } from "thirdweb";
import { FaWallet } from "react-icons/fa6";

const WalletDropdown = ({
  user,
  userBalance,
  disconnectWallet,
  isFetching,
  userBalanceFetching,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useProfile(user);
  const { theme } = useTheme();

  const client = createThirdwebClient({
    clientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID,
  });

  const walletDetailsModal = useWalletDetailsModal();
  
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0px";
    }
    return () => {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    };
  }, [isOpen]);

  if (!user || isFetching || userBalanceFetching || !data) return null;

  const formatAddress = (address) =>
    `${address?.slice(0, 6)}...${address?.slice(-4)}`;

  const handleWalletDetailsClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await walletDetailsModal.open({
        chains: [polygon], 
        theme,
        client,
      });
    } catch (error) {
      console.error("Failed to open wallet details:", error);
    }
  };

  const handleDisconnect = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
    // Get all cookies and find Thirdweb related ones

      await disconnectWallet();
    } catch (error) {
      console.error("Failed to disconnect wallet:", error);
    }
  };

  const MenuItems = [
    {
      icon: <LuLayoutDashboard className="w-4 h-4" />,
      text: "Dashboard",
      href: "/dashboard",
      type: "link",
    },
    {
      icon: <FaWallet className="w-4 h-4" />,
      text: "My Wallet",
      type: "button",
      class:" text-left text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300 ",
      onClick: handleWalletDetailsClick,
    },
    {
      icon: <BsReceipt className="w-4 h-4" />,
      text: "Billing & Plans",
      href: "/dashboard/profile",
      type: "link",
    },
    {
      icon: <FaRegUser className="w-4 h-4" />,
      text: "Account Settings",
      href: "/dashboard/profile",
      type: "link",
    },
    {
      icon: <FiSun className="w-4 h-4" />,
      text: "Theme",
      component: <DarkMode />,
      type: "component",
    },
    // { type: "divider" },
    {
      icon: <MdLogout className="w-4 h-4" />,
      text: "Signout",
      onClick: handleDisconnect,
      type: "button",
      class:" border-jacarta-300 dark:hover:bg-jacarta-600 hover:bg-jacarta-900 hover:text-white gap-2 group  hover:border-transparent dark:bg-gradient-to-tr from-hotPink via-purplish to-accent w-full rounded-b-lg  flex justify-center font-semibold py-4 "
    },
  ];

  return (
    <div className="relative inline-block text-left">
      <Menu>
        {({ open }) => {
          if (open !== isOpen) {
            setIsOpen(open);
          }

          return (
            <>
              <Menu.Button className="border border-jacarta-300 dark:hover:bg-jacarta-600 hover:bg-jacarta-900 hover:text-white dark:bg-jacarta-700 hover:bg-gray-50 rounded-lg p-2  flex items-center gap-2 w-[166px] cursor-pointer transition-colors hover:border-transparent group">
                {data?.metadata?.profileImage ? (
                  <Image
                    src={data.metadata.profileImage}
                    width={20}
                    height={20}
                    alt="Wallet logo"
                  />
                ) : (
                  <FaUser className="w-5 h-5 text-gray-500" />
                )}
                <div>
                  <p
                    title={data?.walletAddress}
                    className="line-clamp-1 w-24 text-sm font-medium mb-1 group-hover:text-white text-start"
                  >
                    {formatAddress(data?.walletAddress)}
                  </p>
                  <p className="text-xs text-start group-hover:text-white">
                    {(userBalance)} MATIC
                  </p>
                </div>
                <FaAngleDown
                  className={`ml-2 transition-transform duration-200 ${
                    open ? "rotate-180" : ""
                  }`}
                />
              </Menu.Button>

              <Menu.Items className="absolute right-0 mt-2 w-64  rounded-xl bg-white dark:bg-jacarta-800 shadow-md dark:shadow-jacarta-600  focus:outline-none shadow-jacarta-300  ">
                <div className="px-3 pt-3 pb-0">
                  <div className="flex items-center gap-3 mb-1">
                    <Image
                      src={data?.metadata?.profileImage}
                      width={40}
                      height={40}
                      alt="User profile"
                      className="rounded-full"
                    />
                    <div className="w-full">
                      <p className="font-semibold text-xs line-clamp-1">
                        {data?.email}
                      </p>
                      <p className="text-xs mt-1 flex justify-between items-start">
                        {formatAddress(data?.walletAddress)}
                        <CopyToClipboard
                          text={user}
                          onCopy={() => toast.success("Copied")}
                        >
                          <IoCopyOutline className="dark:text-white w-4 h-4 cursor-pointer" />
                        </CopyToClipboard>
                      </p>
                    </div>
                  </div>
                </div>
                <hr className="my-2 border-jacarta-200 dark:border-jacarta-600" />

                {MenuItems.map((item, index) => (
                  <Menu.Item key={index} as="div">
                    {({ active }) => {
                      const className = `
                        ${active ? "bg-jacarta-200 dark:bg-jacarta-600" : ""}
                        flex items-center gap-2  px-3 py-3 w-full    hover:bg-jacarta-900
                      `;

                      if (item.type === "link") {
                        return (
                          <Link href={item.href} className={`${className}  text-left text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300`}>
                            {item.icon}
                            <span className="text-sm ">
                              {item.text}
                            </span>
                          </Link>
                        );
                      }

                      if (item.type === "component") {
                        return (
                          <div
                            className={`text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900  flex items-center justify-between  px-3`}
                          >
                            <div className="flex items-center gap-2">
                              {item.icon}
                              <span className="text-sm ">
                                {item.text}
                              </span>
                            </div>
                            <div>{item.component}</div>
                          </div>
                        );
                      }
                      if (item.type === "divider") {
                      return(  <hr className="my-2 border-jacarta-200 dark:border-jacarta-600" />)
                      }
                      if (item.type === "button") {
                        return (
                          <button
                          className={`${className}  ${item.class} `}
                            onClick={(e) => item.onClick(e)}
                          >
                            {item.icon}
                            <span className="text-sm ">
                              {item.text}
                            </span>
                          </button>
                        );
                      }

                      return (
                        <div className={`${className}`}>
                          {item.icon}
                          <span className="text-sm dark:text-white">
                            {item.text}
                          </span>
                        </div>
                      );
                    }}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </>
          );
        }}
      </Menu>
    </div>
  );
};

export default WalletDropdown;
