import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { comingSoonModalhide } from "../../redux/counterSlice";

import ComingSoon from "./coming-soon";
import Image from "next/image";
import { useFormspark } from "@formspark/use-formspark";
import Loading from "../Loading";

const Coming_Soon_Modal = () => {
  const comingSoon = useSelector((state) => state.counter.comingSoonModal);
  const dispatch = useDispatch();

  const formRef = useRef();
  const FORMSPARK_FORM_ID = "HPEVBpuX";
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = {};
    const formElements = formRef.current.elements;

    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      if (element.name) {
        formData[element.name] = element.value;
      }
    }
    await submit({ ...formData });
    dispatch(comingSoonModalhide())

    formRef.current.reset()
    setTimeout(() => {
      toast.success("Thanks for subscribing!")

    }, 300);

  };
  return (
    <div className="">
      {/* <!-- Wallet Modal --> */}
      <div
        className={comingSoon ? "block modal fade show p-4 z-[99999999] " : "modal fade hidden"}
      >
        <div className="modal-dialog max-w-[900px] w-full  m-auto">
          <div className="modal-content gradient-box  dark:bg-jacarta-900">
            <div className="modal-header border-0">
              {/* <h5 className="modal-title" id="comingSoonLabel">
                Choose your wallet
              </h5> */}
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(comingSoonModalhide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-900 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <form className="modal-body p-4 overflow-hidden" ref={formRef} onSubmit={onSubmit}>
              <div>
                <div className=' flex flex-col-reverse  lg:flex-row justify-center items-center gap-10 lg:p-10'>
                  <div className='lg:w-1/2'>
                    <h5 className='text-jacarta-900 dark:text-white text-[30px] font-semibold lg:text-[32px] leading-tight mb-3'>
                      Get notified when we launch
                    </h5>
                    <p className='dark:text-[#b5b6bb] text-jacarta-900 text-base lg:text-md'>
                      Unlock exclusive benefits! Join our community for early access to product launches, discounts, incentives, and more!
                    </p>
                    <div>
                      <input type="email" placeholder="Enter your Email..." className="rounded-lg placeholder:dark:text-white bg-jacarta-500 dark:bg-jacarta-900 w-full py-[20px] lg:py-[26px] px-[16px] lg:px-[24px] mt-6 mb-8 hover:ring-accent/10 focus:ring-accent z-[999999]" />

                      <button type="submit" className={`text-center justify-center rounded-md text-[18px] font-medium  py-[20px] px-[22px] md:py-[26px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 w-full block md:inline  ${!submitting ? " bg-gradient-to-tr from-hotPink via-purplish to-accent" : " disabled:text-transparent disabled:bg-jacarta-800"}  relative `}
                        disabled={submitting}>  {submitting ? <Loading size={5} /> : "Recieve launch updates"}</button>
                    </div>
                  </div>
                  <div className='lg:w-1/2'>
                    <ComingSoon />
                  </div>
                </div>

              </div>
              <div className={` w-full absolute md:left-20 lg:left-0  bottom-[-30%] md:bottom-[-28%] z-[1]  dark:mix-blend-lighten mix-blend-difference overflow-hidden`} >
                <div className="relative  h-[300px] w-[600px] lg:w-[1200px] animate-fade  ">
                  <Image
                    fill
                    sizes="100vw"
                    src="/images/hero2.webp"
                    alt="wallet-background"
                    className="object-cover "
                  />
                </div>
              </div>
            </form>


          </div>
        </div>
      </div>
    </div>
  );
};

export default Coming_Soon_Modal;
