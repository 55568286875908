import React, { useMemo } from "react";

import {
	ThirdwebProvider
} from "thirdweb/react";

import { useSelector } from "react-redux";
import { inAppWallet } from "thirdweb/wallets";
import { useRouter } from "next/router";

function ThirdWebCustomProvider({ children }) {
	const { cartDrawer: isOpen } = useSelector((state) => state.cart);
	const router = useRouter();
	const isSignInPage = router.pathname === '/signin';

	const wallets = useMemo(() => {
		if (isSignInPage) {
			return [
				inAppWallet({
					auth: {
						options: ["email", "google", "apple", "facebook"]
					}
				})
			];
		}
		
		return [
			inAppWallet({
				auth: {
					options: ["email"]
				}
			})
		];
	}, [isSignInPage]);

	return (

		<ThirdwebProvider
			activeChain={process.env.NEXT_PUBLIC_ACTIVE_CHAIN}
			clientId={process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID}
			
			authConfig={{
				domain:   process.env.NODE_ENV == 'production' ? 'https://netzylo-testing.vercel.app' : "localhost:3000" ,
				authUrl: "/api/auth/thirdweb"
			}}

			supportedWallets={wallets}
		>
			{children}

		</ThirdwebProvider>

	);
}




export default ThirdWebCustomProvider;
