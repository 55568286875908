import { useRouter } from 'next/router';
import { useCallback } from 'react';



export const useAuthRedirect = () => {
  const router = useRouter();

  const getRedirectPath = useCallback(() => {
    console.log(router.query)
    if (router.isReady && typeof router.query.redirect === 'string') {
      return router.query.redirect;
    }
    return '/';
  }, [router.isReady, router.query.redirect]);

  const redirectToSignIn = useCallback(() => {
    const currentPath = router.asPath;
    router.push(`/signin?redirect=${encodeURIComponent(currentPath)}`);
  }, [router.asPath]);

  const redirectAfterAuth = useCallback(async () => {
    const redirectPath = getRedirectPath();
    console.log(redirectPath)

    await router.push(redirectPath);
  }, [getRedirectPath]);

  return {
    redirectToSignIn,
    redirectAfterAuth,
    getRedirectPath,
  };
}; 