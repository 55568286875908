import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

export const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state,action) => {
      const { payload } = action;
      
      state.user = payload;
    },
    logoutUser: (state) => {
      state.user = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, logoutUser } = authReducer.actions;

export default authReducer.reducer;
