import React from "react";
import Image from "next/image";
import Link from "next/link";
import Logo from "./../../public/Logo-dark.png";
import WhiteLogo from "./../../public/Logo-white.png";
import { useEffect, useState } from "react";
import DarkMode from "../mode/DarkMode";

import { useDispatch, useSelector } from "react-redux";
import { FaCartShopping } from "react-icons/fa6";
import { cartDrawerToggle } from "../../redux/cartSlice";

export default function Header03() {
  const [toggle, setToggle] = useState(false);
  const [isScroll, setScroll] = useState(false);

  // sticky menu
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 4) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, []);

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const dispatch = useDispatch();

  const isLoaded = true;

  const { cartItems } = useSelector((state) => state.cart);

  return (
    <>
      <header
        className={` js-page-header fixed top-0 z-20 backdrop-blur w-full  transition-colors ${
          isScroll ? "js-page-header--is-sticky " : ""
        }`}
      >
        <div className="flex  container mx-auto w-full items-center justify-between px-4 py-3 ">
          <Link className="shrink-0" href="/">
            <div className="dark:hidden">
              <Image
                src={WhiteLogo}
                height={28}
                width={90}
                alt="Netzylo"
                className=" h-auto "
              />
            </div>
            <div className="hidden dark:block">
              <Image src={Logo} height={28} width={90} alt="Netzylo" />
            </div>
          </Link>
       
        <div className="  justify-end flex">
          {isLoaded ? (
            <>
              <div
                onClick={() => dispatch(cartDrawerToggle())}
                className="border-jacarta-100  relative hover:bg-accent dark:hover:bg-accent mr-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15] cursor-pointer"
                aria-label="Cart"
              >
                <FaCartShopping />
                {cartItems.length > 0 && (
                  <div className="bg-accent w-4 h-4 text-sm absolute top-0 right-0 rounded-full text-center text-jacarta-900">
                    {cartItems.length}
                  </div>
                )}
              </div>

              <DarkMode />
            </>
          ) : (
            <div className="animate-pulse bg-jacarta-200 dark:bg-jacarta-700 rounded-md p-4  min-w-[10rem]"></div>
          )}
        </div> 
        </div>
        {/* <div className="ml-auto flex justify-center lg:hidden">
            {isLoaded ? (
              <>
                <div
                  onClick={() => dispatch(cartDrawerToggle())}
                  className="border-jacarta-100  relative hover:bg-accent dark:hover:bg-accent mr-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15] cursor-pointer"
                  aria-label="Cart"
                >
                  <FaCartShopping />
                  {cartItems.length > 0 && (
                    <div className="bg-accent w-4 h-4 text-sm absolute top-0 right-0 rounded-full text-center text-jacarta-900">
                      {cartItems.length}
                    </div>
                  )}
                </div>

                <DarkMode />
              </>
            ) : (
              <div className="animate-pulse bg-jacarta-200 dark:bg-jacarta-700 rounded-md p-4  min-w-[10rem]"></div>
            )}
          </div> */}
      </header>

      {/* <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? "nav-menu--is-open" : "hidden"
        }`}
      >
        <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
          <div className="dark:hidden">
            <Image
              src={Logo}
              height={28}
              width={130}
              alt="Netzylo"
              className=" h-auto "
            />
          </div>

          <div className="hidden dark:block">
            <Image src={WhiteLogo} height={28} width={130} alt="Netzylo" />
          </div>

          <button
            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-900 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
      </div> */}
    </>
  );
}
