import Link from "next/link";
import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import { FaBars, FaChevronDown } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import {  FiMoon, FiSun } from "react-icons/fi";
import Drawer from "react-modern-drawer";
import { useRouter } from "next/router";
import { useTheme } from "next-themes";
import Image from "next/image";
import useProfile from "../hooks/queryHooks/getUser";
import { useActiveAccount } from "thirdweb/react";
import { FiLogOut } from "react-icons/fi";
import { useWalletDisconnect } from "../hooks/useWalletDisconnect";
import { createContext, useContext } from "react";
import DarkModeSwitcher from "./mode/DarkMode";

// Memoize child components
const ProfileSkeleton = memo(function ProfileSkeleton() {
  return (
    <div className="relative mb-2">
      <div className="absolute inset-0 bg-gradient-to-br from-hotPink/20 via-accent/15 to-transparent rounded-2xl" />

      <div className="relative p-5 rounded-2xl">
        <div className="flex items-start gap-4 mb-4">
          <div className="relative flex-shrink-0">
            <div className="w-14 h-14 rounded-xl bg-jacarta-200 dark:bg-jacarta-700 animate-pulse" />
            <div className="absolute bottom-0 right-0 w-3 h-3 bg-jacarta-200 dark:bg-jacarta-700 rounded-full border-2 border-white dark:border-jacarta-900 animate-pulse" />
          </div>

          <div className="flex-1 min-w-0">
            {/* Username skeleton */}
            <div className="h-5 w-32 bg-jacarta-200 dark:bg-jacarta-700 rounded-md mb-2 animate-pulse" />

            {/* Location skeleton */}
            <div className="flex items-start gap-1.5">
              <div className="w-3.5 h-3.5 bg-jacarta-200 dark:bg-jacarta-700 rounded-md mt-0.5 flex-shrink-0 animate-pulse" />
              <div className="h-4 w-24 bg-jacarta-200 dark:bg-jacarta-700 rounded-md animate-pulse" />
            </div>
          </div>
        </div>

        {/* Bio skeleton */}
        <div className="space-y-2">
          <div className="h-4 w-full bg-jacarta-200 dark:bg-jacarta-700 rounded-md animate-pulse" />
          <div className="h-4 w-3/4 bg-jacarta-200 dark:bg-jacarta-700 rounded-md animate-pulse" />
        </div>
      </div>
    </div>
  );
});

const ProfileSection = memo(function ProfileSection() {
  const address = useActiveAccount();
  const { data: profile, isLoading } = useProfile(address);

  if (isLoading) {
    return <ProfileSkeleton />;
  }

  return (
    <Link className="relative mb-2" href={'/dashboard/profile'}>
      <div className="absolute inset-0 dark:bg-jacarta-800  rounded-2xl shadow-md shadow-jacarta-200 dark:shadow-jacarta-600 " />

      <div className="relative p-5 rounded-2xl">
        <div className="flex items-start gap-4 mb-4">
          <div className="relative flex-shrink-0">
            {profile?.metadata?.profileImage ? (
              <div className="relative">
                <Image
                  src={profile.metadata.profileImage}
                  alt="Profile"
                  className="w-14 h-14 rounded-xl object-cover shadow-sm"
                  width={56}
                  height={56}
                />
              </div>
            ) : (
              <div className="w-14 h-14 rounded-xl bg-gradient-to-br from-accent/20 to-accent/30 flex items-center justify-center shadow-sm">
                <span className="text-xl font-bold text-accent">
                  {profile?.metadata?.username?.charAt(0) || "U"}
                </span>
              </div>
            )}
          </div>

          <div className="flex-1 min-w-0">
            <h3 className="font-semibold text-base  mb-1">
              {profile?.metadata?.username || "Username"}
            </h3>

            {(profile?.metadata?.city || profile?.metadata?.country) && (
              <div className="flex items-start gap-1.5">
                <HiOutlineLocationMarker className="w-3.5 h-3.5  mt-0.5 flex-shrink-0" />
                <span className="text-sm  break-words">
                  {[profile.metadata?.city, profile.metadata?.country]
                    .filter(Boolean)
                    .join(", ")}
                </span>
              </div>
            )}
          </div>
        </div>

        {profile?.metadata?.bio && (
          <div className="mb-3 text-sm ">
            <p className="line-clamp-2 ">{profile.metadata.bio}</p>
          </div>
        )}
      </div>
    </Link>
  );
});

const NavItem = memo(function NavItem({ item, isNested }) {
  const router = useRouter();
  const { openMenus, toggleMenu } = useSidebarContext();

  // Fix the isActive logic
  const isActive = useMemo(() => {
    // Exact match for dashboard
    if (item.path === "/dashboard") {
      return router.asPath === "/dashboard";
    }

    // For nested items, check exact match
    if (isNested) {
      return router.asPath === item.path;
    }

    // For parent items with children, check if any child is active
    if (item.children) {
      return item.children.some((child) =>
        router.asPath.startsWith(child.path)
      );
    }

    // For other routes, check if current path starts with item path
    // but only if item.path is not just '/'
    return item.path !== "/" && router.asPath.startsWith(item.path);
  }, [router.asPath, item.path, item.children, isNested]);

  const isOpen = openMenus.includes(item.name);

  const ComingSoonWrapper = ({ children }) => (
    <div className="relative group w-full">
      {children}
      {item.comingSoon && (
        <div
          className="absolute opacity-0 group-hover:opacity-100 transition-opacity duration-200 
                      bg-accent text-jacarta-900 text-xs py-1 px-2 rounded-md whitespace-nowrap
                      left-1/2 -top-2 transform -translate-x-1/2 -translate-y-full group-hover:z-50 -z-10"
        >
          Coming Soon
          <div
            className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-1/2
                        border-4 border-transparent border-t-accent"
          />
        </div>
      )}
    </div>
  );

  if (item.children) {
    return (
      <div className="mb-1.5">
        <ComingSoonWrapper>
          <button
            onClick={() => toggleMenu(item.name)}
            className={`w-full flex items-center justify-between group p-2.5 rounded-xl  ${
              isActive || isOpen
                ? "text-jacarta-900 dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900"
                : "text-jacarta-900 dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900"
            }`}
          >
            <div className="flex items-center gap-3">
              <item.icon
                className={`w-5 h-5 ${
                  isActive || isOpen
                    ? "text-accent group-hover:text-white dark:group-hover:text-jacarta-900"
                    : "text-jacarta-600 dark:text-white group-hover:text-white dark:group-hover:text-jacarta-900"
                }`}
              />
              <span className="text-sm group-hover:text-white dark:group-hover:text-jacarta-900">{item.name}</span>
            </div>
            <FaChevronDown
              className={`w-3 h-3 transition-transform duration-200 dark:group-hover:text-jacarta-900 group-hover:text-white ${
                isOpen ? "rotate-180" : ""
              } ${
                isActive || isOpen
                  ? "text-accent"
                  : "text-jacarta-900 dark:text-white"
              }`}
            />
          </button>
        </ComingSoonWrapper>
        {isOpen && (
          <div className="mt-1 ml-3 pl-4 border-l border-jacarta-100 dark:border-accent/20">
            {item.children.map((child) => (
              <NavItem key={child.path} item={child} isNested />
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <ComingSoonWrapper>
      <Link
        href={item.comingSoon ? "#" : item.path}
        target={item.outer ? "_blank" : ""}
        className={`flex items-center group justify-between p-2.5 rounded-xl  text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300  hover:bg-jacarta-900  ${
          isNested ? "py-2" : ""
        } 
           ${
          isActive
            ? isNested
              ? " text-accent shadow-sm bg-accent/20 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 "
              : " text-accent shadow-sm bg-accent/20 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 "
            : `${
                isNested
                  ? " text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 "
                  : " text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 "
              }`
        }
        `}
      >
        <div className="flex items-center gap-3">
          <item.icon
            className={`w-5 h-5 ${
              isActive
                ? "text-accent  group-hover:text-white dark:group-hover:text-jacarta-900"
                : isNested
                ? "text-jacarta-900 dark:text-white group-hover:text-white dark:group-hover:text-jacarta-900"
                : "text-jacarta-900 dark:text-white group-hover:text-white dark:group-hover:text-jacarta-900"
            }`}
          />
          <span className={`text-sm group-hover:text-white dark:group-hover:text-jacarta-900 ${isNested ? "text-inherit" : ""}`}>
            {item.name}
          </span>
        </div>
      </Link>
    </ComingSoonWrapper>
  );
});

const ThemeToggle = memo(function ThemeToggle({ theme, setTheme }) {
  return (


    <div
      className="flex items-center justify-between text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 p-2 rounded-md cursor-pointer"
      onClick={() => setTheme(theme === "light" ? "dark" : "light")}
    >
      <div className="flex items-center ">
        {theme === "dark" ? (
          <FiSun className="mr-3 h-6 w-6" />
        ) : (
          <FiMoon className="mr-3 h-6 w-6" />
        )}
        Theme
      </div>
      <DarkModeSwitcher />
    </div>
  );
});

const SignOutButton = memo(function SignOutButton({ handleDisconnect }) {
  return (
    <button
      onClick={() => handleDisconnect()}
      className="w-full my-4 text-center font-semibold text-lg btn-gradient py-2 flex items-center justify-center gap-2 rounded-md"
    >
      <FiLogOut className="w-5 h-5" />
      <span className="text-sm">Sign Out</span>
    </button>
  );
});

// Create a context for sidebar state
const SidebarContext = createContext();

const useSidebarContext = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebarContext must be used within SidebarProvider");
  }
  return context;
};

export default function Sidebar({ routes }) {
  const { theme, setTheme } = useTheme();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [openMenus, setOpenMenus] = useState([]);
  const address = useActiveAccount();
  const { data: profile } = useProfile(address);
  const { handleDisconnect } = useWalletDisconnect();

  const toggleDrawer = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const toggleMenu = useCallback((name) => {
    setOpenMenus((prev) =>
      prev.includes(name) ? prev.filter((n) => n !== name) : [...prev, name]
    );
  }, []);

  // Memoize the context value
  const contextValue = useMemo(
    () => ({
      openMenus,
      toggleMenu,
    }),
    [openMenus, toggleMenu]
  );

  // Effect for setting initial open menus
  useEffect(() => {
    const activeParents = routes
      .filter(
        (route) =>
          route.children &&
          route.children.some((child) => {
            if (child.path === "/dashboard" && router.asPath === "/dashboard") {
              return true;
            }
            return (
              child.path !== "/dashboard" &&
              router.asPath.startsWith(child.path)
            );
          })
      )
      .map((route) => route.name);
    setOpenMenus(activeParents);
  }, [router.asPath, routes]);

  return (
    <SidebarContext.Provider value={contextValue}>
      <aside className="hidden md:flex flex-col flex-shrink-0 w-72 bg-white dark:bg-jacarta-900">
        <div className="p-5">{profile && <ProfileSection />}</div>

        <nav className="flex-1 px-5 pb-5 overflow-y-auto">
          {/* Main Navigation Section */}
          <div className="space-y-1.5 mb-6">
            {/* <div className="mb-2 px-2">
              <span className="text-xs font-medium text-jacarta-400 dark:text-white uppercase">
                Main Navigation
              </span>
            </div> */}
            {routes.map((item) => (
              <NavItem key={item.path} item={item} />
            ))}
          </div>

          {/* Divider */}
          <div
            className="h-px bg-gradient-to-r from-white via-jacarta-500 to-transparent 
                          dark:from-jacarta-600 dark:via-jacarta-600 my-6"
          />

          {/* Settings Section */}
          <div className="space-y-1.5 mb-6">
            <ThemeToggle theme={theme} setTheme={setTheme} />
          </div>

          {/* Divider */}
          <div
            className="h-px bg-gradient-to-r from-white via-jacarta-500 to-transparent 
                          dark:from-jacarta-600 dark:via-jacarta-600 my-6"
          />

          {/* Account Section */}
          <div className="space-y-1.5">
            <SignOutButton handleDisconnect={handleDisconnect} />
          </div>
        </nav>
      </aside>

      {/* Mobile drawer with same optimizations */}
      <div className="md:hidden">
        <button onClick={toggleDrawer} className="p-4">
          <FaBars className="w-6 h-6 text-jacarta-900 dark:text-white" />
        </button>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className="!w-[300px]"
          style={{ background: theme === "dark" ? "#07091b" : "white" }}
        >
          <div className="flex flex-col h-full">
            <div className="p-4">
              <Link className="shrink-0 mb-4 inline-block" href="/">
                <div className="dark:hidden">
                  <Image
                    src="/mbl.svg"
                    height={28}
                    width={28}
                    alt="Netzylo"
                    className="h-auto"
                  />
                </div>
                <div className="hidden dark:block">
                  <Image
                    src="/mbl-dark.svg"
                    height={28}
                    width={28}
                    alt="Netzylo"
                  />
                </div>
              </Link>
              <ProfileSection />
            </div>

            <nav className="flex-1 px-4 overflow-y-auto">
              {/* Main Navigation Section */}
              <div className="space-y-1.5 mb-6">
                <div className="mb-2 px-2">
                  <span className="text-xs font-medium text-jacarta-400 dark:text-white uppercase">
                    Main Navigation
                  </span>
                </div>
                {routes.map((item) => (
                  <NavItem key={item.path} item={item} />
                ))}
              </div>

              {/* Divider */}
              <div
                className="h-px bg-gradient-to-r from-white via-jacarta-500 to-transparent 
                              dark:from-jacarta-600 dark:via-jacarta-600 my-6"
              />

              {/* Settings Section */}
              <div className="space-y-1.5 mb-6">
                <ThemeToggle theme={theme} setTheme={setTheme} />
              </div>

              {/* Divider */}
              <div
                className="h-px bg-gradient-to-r from-white via-jacarta-500 to-transparent 
                              dark:from-jacarta-600 dark:via-jacarta-600 my-6"
              />

              {/* Account Section */}
              <div className="space-y-1.5 pb-4">
                <SignOutButton handleDisconnect={handleDisconnect} />
              </div>
            </nav>
          </div>
        </Drawer>
      </div>
    </SidebarContext.Provider>
  );
}
