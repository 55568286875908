import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { socialSharehide } from "../../redux/counterSlice";
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  XIcon,
  RedditIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import toast from "react-hot-toast";

const SocialShare = () => {
  // Retrieve the socialShare state from Redux store
  const socialShare = useSelector((state) => state.counter.socialShare);
  const dispatch = useDispatch();

  // Define the URL to share
  const shareUrl = "https://netzylo.com"; // Ensure it's a full URL with protocol

  return (
    <div>
      {/* <!-- Social Share Modal --> */}
      <div
        className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity ${
          socialShare
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className={`bg-white dark:bg-jacarta-800 rounded-lg shadow-lg w-11/12 max-w-md mx-auto p-6 relative transform transition-transform duration-300 ${
            socialShare ? "scale-100" : "scale-95"
          }`}
        >
          {/* Modal Header */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-jacarta-800 dark:text-white">
              Share This Page
            </h2>
            <button
              onClick={() => dispatch(socialSharehide())}
              className="text-jacarta-600 dark:text-jacarta-300 hover:text-jacarta-800 dark:hover:text-white focus:outline-none"
              aria-label="Close Modal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {/* Close Icon */}
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Modal Body */}
          <div className="flex flex-col items-center">
            {/* Social Share Buttons */}
            <div className="flex flex-wrap justify-center space-x-4 mb-4">
              <FacebookShareButton
                url={shareUrl}
                quote="Check out this awesome website!"
              >
                <FacebookIcon size={48} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                title="Check out this awesome website!"
              >
                <XIcon size={48} round />
              </TwitterShareButton>
              <RedditShareButton
                url={shareUrl}
                title="Check out this awesome website!"
              >
                <RedditIcon size={48} round />
              </RedditShareButton>
              <LinkedinShareButton
                url={shareUrl}
                title="Check out this awesome website!"
              >
                <LinkedinIcon size={48} round />
              </LinkedinShareButton>
              <WhatsappShareButton
                url={shareUrl}
                title="Check out this awesome website!"
              >
                <WhatsappIcon size={48} round />
              </WhatsappShareButton>
              <EmailShareButton
                url={shareUrl}
                subject="Check this out!"
                body="Check out this awesome website!"
              >
                <EmailIcon size={48} round />
              </EmailShareButton>
            </div>

            {/* Copy Link Feature */}
            <div className="w-full mt-4">
              <label htmlFor="shareLink" className="sr-only">
                Shareable Link
              </label>
              <div className="flex items-center border rounded-md overflow-hidden">
                <input
                  type="text"
                  id="shareLink"
                  value={shareUrl}
                  readOnly
                  className="w-full px-3 py-2 text-jacarta-700 dark:text-jacarta-300 bg-jacarta-100 dark:bg-jacarta-700 focus:outline-none"
                />
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(shareUrl);
                    toast.success("Link copied to clipboard!");
                  }}
                  className="px-4 py-2 text-jacarta-700 dark:text-jacarta-300 bg-jacarta-100 dark:bg-jacarta-700  focus:outline-none"
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialShare;
