import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { renewalModalhide } from "../../redux/counterSlice";

const Renewel_Modal = () => {

    const renewalModal = useSelector((state) => state.counter.renewalModal);
    const dispatch = useDispatch();

    return (
        <div className="">
            {/* <!-- Wallet Modal --> */}
            <div
                className={renewalModal ? "block modal fade show p-4 fixed z-[999999]  " : "modal fade hidden"}
            >
                <div className="modal-dialog max-w-lg w-full gradient-box ">
                    <div className="modal-content   dark:bg-jacarta-900  overflow-auto m-0  rounded-md opacity-100 right-0">
                        <div className="modal-header">
                            {/* <h5 className="modal-title" id="renewelModalLabel">
                            <Image src='/logo-token.png' width={50} height={50} className='mb-6 mx-auto' />
                            </h5> */}
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => dispatch(renewalModalhide())}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="fill-jacarta-900 h-6 w-6 dark:fill-white"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                                </svg>
                            </button>
                        </div>
                        {/* <!-- Body --> */}
                        <div className="modal-body p-6 ">
                            <div className='text-jacarta-900  dark:text-white'>
                                <h2 className='text-xl lg:text-2xl font-semibold'>Own your Netzylo Domain Forever - No Renewals Required!</h2>
                                <p className='text-xs lg:text-sm my-6'>Enjoy ownership withNetzylo Domains, say goodbye to renewals.</p>
                                <button className="inline-flex justify-center rounded-md  w-full py-4 text-sm font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent transition-transform duration-500 hover:-translate-y-1 button z-[2] "
                                >Got it</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Renewel_Modal
