import { cartDrawerToggle } from "../../redux/cartSlice";
import { useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import {

  FiUsers,
  FiPlus,
  FiCreditCard,
  FiShoppingCart,
  FiImage,
  FiDroplet,
  FiFolderPlus,
  FiMoon,
  FiSun,
  FiChevronDown,
  FiX,
  FiLogOut,
  FiZap,
} from "react-icons/fi";
import { RiNftLine, RiPagesLine } from "react-icons/ri";

import Image from "next/image";
import DarkMode from "../mode/DarkMode";
import { useActiveAccount} from "thirdweb/react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { useTheme } from "next-themes";
import { useWalletDisconnect } from "../../hooks/useWalletDisconnect";
import Link from "next/link";
import { FaArrowRight } from "react-icons/fa6";

export default function Component({ isOpen, toogle }) {
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const { handleDisconnect } = useWalletDisconnect();

  const dispatch = useDispatch();
  const router = useRouter();
  const { theme, setTheme } = useTheme();
  const { cartItems } = useSelector((state) => state.cart);

  const toggleCreate = () => setIsCreateOpen(!isCreateOpen);
  const user = useActiveAccount();
  function signout() {
    handleDisconnect();
    toogle();
  }

  const toggleTheme = () => {
    if (theme === "light") setTheme("dark");
    else setTheme("light");
  };
  async function navigateTo(path) {
    await router.push(path);
    toogle();
  }
  return (
    <div>
      <Drawer
        lockBackgroundScroll
        open={isOpen}
        onClose={toogle}
        direction="left"
        className="!w-72"
      >
        <div className="flex flex-col h-full bg-white dark:bg-jacarta-900 ">
          <div className="flex items-center justify-between px-6 py-4 mt-5 border-b border-gray-200 dark:border-gray-800">
            <div className="flex items-center">
              <div className="dark:hidden">
                <Image
                  src="/Logo-white.png"
                  height={28}
                  width={90}
                  alt="Netzylo"
                  className=" h-auto "
                />
              </div>
              <div className="hidden dark:block">
                <Image
                  src="/Logo-dark.png"
                  height={28}
                  width={90}
                  alt="Netzylo"
                />
              </div>
            </div>
            {/* close button  */}
            <button type="button" className="btn-close" onClick={toogle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-jacarta-900 h-6 w-6 dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
              </svg>
            </button>
          </div>
          <div className="flex-1 overflow-y-auto mt-2 custom-scrollbar">
            <nav className="p-4 space-y-2">
              <Link
                href='/domains'
                className="w-full flex items-center text-sm font-normal text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 p-2 rounded-md"
              >
    
                <RiNftLine className="mr-3 h-6 w-6" />
                 Domains
              </Link>

              {/* <button
                onClick={() => navigateTo("/community")}
                className="w-full flex items-center text-sm font-normal text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 p-2 rounded-md"
              >
                <FiUsers className="mr-3 h-6 w-6" />
                Create
              </button> */}

              <div>
                <button
                  className="w-full flex items-center text-sm font-normal text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 p-2 rounded-md"
                  onClick={toggleCreate}
                >
                  <FiPlus className="mr-3 h-6 w-6" />
                  Create
                  <FiChevronDown
                    className={`ml-auto h-6 w-6 transform transition-transform ${
                      isCreateOpen ? "rotate-180" : ""
                    }`}
                  />
                </button>
                {isCreateOpen && (
                  <div className="ml-6 space-y-1 mt-2">
                    <Link  href='/community' className="w-full flex items-center text-sm font-normal text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 p-2 rounded-md">
                      <FiImage className="mr-3 h-6 w-6" />
                      NFT
                    </Link>
                    <Link  href='/community' className="w-full flex items-center text-sm font-normal text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 p-2 rounded-md">
                      <FiDroplet className="mr-3 h-6 w-6" />
                      Drop
                    </Link>
                    <Link  href='/community' className="w-full flex items-center text-sm font-normal text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 p-2 rounded-md">
                      <FiFolderPlus className="mr-3 h-6 w-6" />
                      Collection
                    </Link>
                  </div>
                )}
              </div>

              <Link
                href='/pricing'
                className="w-full flex items-center text-sm font-normal text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 p-2 rounded-md"
              >
                <FiCreditCard className="mr-3 h-6 w-6" />
                Pricing
              </Link>

              <Link
                href='/domains'
                className="w-full flex items-center  text-sm font-normal dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 p-2 rounded-md text-accent"
              >
                              <FiZap className="mr-3 h-6 w-6" />

                Start for Free

                <FaArrowRight className=" h-4 w-4 text-accent ml-auto" />
              </Link>


              <button
                onClick={() => dispatch(cartDrawerToggle())}
                className="w-full flex items-center justify-between text-sm font-normal text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 p-2 rounded-md"
              >
                <div className="flex  items-center">
                  <FiShoppingCart className="mr-3 h-6 w-6" />
                  Cart
                </div>

                {cartItems.length > 0 && (
                  <div className="bg-accent w-6 h-6 text-sm flex items-center justify-center font-semibold  rounded-full text-center text-jacarta-900">
                    {cartItems.length}
                  </div>
                )}
              </button>

              <div
                className="flex items-center justify-between text-jacarta-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-gray-300   hover:bg-jacarta-900 p-2 rounded-md cursor-pointer"
                onClick={toggleTheme}
              >
                <div className="flex items-center  ">
                  {theme === "dark" ? (
                    <FiSun className="mr-3 h-6 w-6" />
                  ) : (
                    <FiMoon className="mr-3 h-6 w-6" />
                  )}
                  Theme
                </div>
                <DarkMode />
              </div>
            </nav>
          </div>

          <div className="p-4 border-t border-gray-200 dark:border-gray-800 ">
            {user ? (
              <>
                {/* <div className="flex items-center justify-between mb-4 hover:bg-accent/20 rounded-md cursor-pointer p-2">
                  <Link
                    href="/dashboard"
                    className="font-semibold text-gray-700 dark:text-white"
                  >
                    Dashboard
                  </Link>
                  <Image src="/logo.png" width={30} height={30} />
                </div> */}

                <button
    onClick={() => signout()}
    className="w-full my-4 text-center font-semibold text-lg btn-gradient py-2 flex items-center justify-center gap-2 rounded-md"
  >
    <FiLogOut className="w-5 h-5" />
    <span className="text-sm">Sign Out</span>
  </button>
              </>
            ) : (
              <button
                onClick={() => navigateTo("/signin")}
                className="block my-4 text-center font-semibold text-lg btn-gradient py-2 rounded-md w-full"
              >
                Login
              </button>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
}
