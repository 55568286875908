
import { useDisconnect, useActiveWallet } from "thirdweb/react";
import Cookies from 'js-cookie';
import { useRouter } from "next/router";
export const useWalletDisconnect = () => {
  const { disconnect } = useDisconnect();
  const wallet = useActiveWallet();
  const navigate = useRouter();
  const handleDisconnect = async () => {
    try {
      // First disconnect the wallet
      if (wallet) {
         disconnect(wallet);
         navigate('/');
      }

      // Clear all auth-related cookies
      const cookies = document.cookie.split(';');
      cookies.forEach(cookie => {
        const name = cookie.split('=')[0].trim();
        if (name.includes('thirdweb') || 
            name.includes('auth') || 
            name === 'walletAddress') {
          Cookies.remove(name, { path: '/' });
        }
      });

      // Clear localStorage
      if (typeof window !== 'undefined') {
        // Clear specific keys related to wallet/auth
        localStorage.removeItem('walletconnect');
        localStorage.removeItem('thirdweb');
        localStorage.removeItem('wagmi.wallet');
        localStorage.removeItem('wagmi.network');
        localStorage.removeItem('wagmi.account');
        
        // Clear sessionStorage
        sessionStorage.clear();
      }

      return true;
    } catch (error) {
      console.error('Disconnect error:', error);
      return false;
    }
  };

  return {
    handleDisconnect
  };
}; 