import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { walletAddonhide } from "../../redux/counterSlice";
import { BiWalletAlt } from 'react-icons/bi';
import { TbWalletOff } from 'react-icons/tb';
import { netzyloWalletToggle } from "../../redux/cartSlice";



const Netzylo_Modal = () => {


  const walletAddonModal = useSelector((state) => state.counter.walletAddonModal);
  const { netzyloWallet } = useSelector((state) => state.cart);

  const dispatch = useDispatch();


  const [answer, setAnswer] = React.useState(netzyloWallet ? 'yes' : "nothanks");

  const handleRadioChange = (e) => {
    setAnswer(e.target.value);
  };

  function closeModal() {
    dispatch(walletAddonhide());
    dispatch(netzyloWalletToggle(answer === 'yes' ? true : false))
  }

  return (
    <div className="">
      {/* <!-- Wallet Modal --> */}
      <div
        className={walletAddonModal ? "block modal fade show p-4 fixed z-[9999999]  " : "modal fade hidden"}
      >
        <div className="modal-dialog max-w-lg w-full gradient-box ">
          <div className="modal-content   dark:bg-jacarta-900  overflow-auto m-0  rounded-md opacity-100 right-0">
            <div className="modal-header">
              <h5 className="modal-title text-lg" id="netzyloModalLabel">
                Protect your domain with Netzylo wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => closeModal()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-900 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6 ">

              <div className="">
                <p className="text-xs md:text-sm dark:text-white text-jacarta-900">
                  Netzylo wallet keeps your domain in a private custodial domain vault. keeping domain seprate from your other assets reduse the risk of hackers stealing your domain or making unauthorized changes.
                </p>
              </div>
              <label htmlFor="yesRadio">
                <div className='cursor-pointer mt-4 border border-gray-300 dark:border-gray-200 rounded-lg p-4'>
                  <p className="text-sm font-medium dark:text-white text-jacarta-900 text-center mb-2">Netzylo Wallet</p>
                  <div className='flex items-center gap-4'>
                    <BiWalletAlt className="text-accent flex-none  w-6 h-6" />
                    <label className='flex items-center gap-2 md:gap-4'>
                      <p className='text-sm dark:text-white text-jacarta-900'>$4/domain/year <span>or</span> $10/domain/year for $100+ domains. <span>Domains are stored in a custodial wallet designed to protect against theft.</span></p>
                      <input
                        type="radio"
                        id="yesRadio"
                        value="yes"
                        checked={answer === 'yes'}
                        onChange={handleRadioChange}
                        className="text-accent bg-gray-100 border-gray-300 focus:ring-accent dark:focus:ring-accent/20 dark:ring-offset-accent/80 focus:ring-2 dark:bg-accent/60 dark:border-gray-600"
                      />
                    </label>
                  </div>
                </div>
              </label>

              {/* 
                    <label htmlFor="noRadio">
                      <div className='cursor-pointer mt-4 border border-gray-300 dark:border-gray-200 rounded-lg p-4'>
                        <p className="text-sm font-medium dark:text-white text-jacarta-900 text-center mb-2">Bulk Netzylo Wallet</p>
                        <div className='flex items-center  gap-4'>
                          <BiWalletAlt className="text-accent  flex-none  w-6 h-6" />
                          <label className='flex items-center gap-2 md:gap-4'>
                            <p className='text-sm dark:text-white text-jacarta-900'>$99/year <span>to store an unlimited number of domains into a custodial wallet designed to protect against theft. </span></p>
                            <input
                              type="radio"
                              id="noRadio"
                              value="no"
                              checked={answer === 'no'}
                              onChange={handleRadioChange}
                              className=" text-accent bg-gray-100 border-gray-300 focus:ring-accent dark:focus:ring-accent/20 dark:ring-offset-accent/80 focus:ring-2 dark:bg-accent/60 dark:border-gray-600 "
                            />
                          </label>
                        </div>
                      </div>
                    </label> */}

              <label htmlFor="nothanks">
                <div className='cursor-pointer mt-4 border border-gray-300 dark:border-gray-200 rounded-lg p-4'>

                  <div className='flex justify-between items-center  gap-4'>
                    <TbWalletOff className="text-accent  flex-none  w-6 h-6" />
                    <p className='text-sm dark:text-white text-jacarta-900'>No Thanks!</p>

                    <label className=''>
                      <input
                        type="radio"
                        id="nothanks"
                        value="nothanks"
                        checked={answer === 'nothanks'}
                        onChange={handleRadioChange}
                        className=" text-accent bg-gray-100 border-gray-300 focus:ring-accent dark:focus:ring-accent/20 dark:ring-offset-accent/80 focus:ring-2 dark:bg-accent/60 dark:border-gray-600 "
                      />
                    </label>
                  </div>
                </div>
              </label>
              <div className="mt-10">
                <button
                  onClick={() => closeModal()}
                  type="button"
                  className="inline-flex justify-center rounded-md  w-full py-4 text-sm font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent transition-all duration-500 hover:-translate-y-1 button z-[2] "

                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Netzylo_Modal;

