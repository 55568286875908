import React from 'react'
import 'react-modern-drawer/dist/index.css'
import Drawer from 'react-modern-drawer'
import { MdClose, MdWarning } from "react-icons/md";
import { FaCartShopping } from 'react-icons/fa6';
import { BsTrash } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from "react";
import { cartDrawerToggle, removeItemFromCart, changeQuantity, addToCart } from "../../redux/cartSlice";
import { useRouter } from 'next/router';
import Image from 'next/image';

import { ConnectButton, useActiveAccount } from "thirdweb/react";
import { useTheme } from 'next-themes';
import toast from 'react-hot-toast';
import { createThirdwebClient } from "thirdweb";
import {
  inAppWallet,
} from "thirdweb/wallets";


function CartDrawer() {

    const dispatch = useDispatch();
    const router = useRouter()
    const  isLoggedIn  = useActiveAccount()
    const [hascardAdded, setHasCardAdded] = React.useState(false)

    const [domains, setDomains] = React.useState({ slds: [], tlds: [] })


    const { cartItems, cartDrawer: isOpen } = useSelector((state) => state.cart);

    const totalPrice = useMemo(() => {
        if (cartItems.length > 0) {
            return cartItems.reduce((acc, curr) => {
                const price = curr.price ? parseFloat(curr.price) : 0;
                return acc + (curr.quantity ? curr.quantity * curr.price : price);
            }, 0);
        }
    }, [cartItems]);

    function removeDomain(item) {
        const filterDomains = cartItems.filter(domain => domain.type === 'TLD')

        const findDomain = filterDomains.filter(domain => (domain.tld === item.tld && item.type === "TLD"));

        if (findDomain.length > 0) {
            const domainsOfTLD = cartItems.filter(ct => (ct.tld === item.tld && ct.type !== "TLD"))
            if (domainsOfTLD.length > 0) {
                toast.custom((t) => (
                    <div
                        className={`${t.visible ? 'animate-enter' : 'animate-leave'
                            } max-w-md w-full bg-white dark:bg-jacarta-600 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                    >
                        <div className="flex-1 w-0 p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0 pt-0.5">
                                    <MdWarning className='h-10 w-10' />

                                </div>
                                <div className="ml-3 flex-1">
                                    Removing this TLD will remove {domainsOfTLD.length} relative domain
                                </div>
                            </div>
                        </div>
                        <div className="flex border-l gap-2 border-gray-200">
                            <button
                                onClick={() => {
                                    for (let i = 0; i < domainsOfTLD.length; i++) {
                                        dispatch(removeItemFromCart(domainsOfTLD[i]))
                                    }
                                    dispatch(removeItemFromCart(item))

                                    toast.dismiss(t.id)
                                }}
                                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                                Yes
                            </button>
                            <button
                                onClick={() => toast.dismiss(t.id)}
                                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                                No
                            </button>
                        </div>
                    </div>
                ))
            } else {
                dispatch(removeItemFromCart(item))

            }
            // toast.error(`Removing this TLD will remove ${domainsOfTLD.length} relative domain `)
        } else {
            dispatch(removeItemFromCart(item))

        }
    }

    function toggleDrawer() {
        dispatch(cartDrawerToggle())
    }

    async function navigateTo(path) {
        await router.push(path)
        dispatch(cartDrawerToggle())

    }
    const { theme } = useTheme();

    // Client and wallet configurations
    const client = createThirdwebClient({
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID,
    });

    const wallets = [
        inAppWallet({
            auth: {
                options: [
                    "email"
                ],
            },
        }),
    ];


    React.useMemo(() => {

        setHasCardAdded(cartItems.filter(ct => ct.type === "card").length > 0)
        const sldDomains = [];
        const tldDomains = [];

        cartItems.forEach(domain => {

            if (domain.type === 'TLD') {
                tldDomains.push(domain);
            } else if (domain.sld) {
                sldDomains.push(domain);
            }

        });

        setDomains({ slds: sldDomains, tlds: tldDomains })


    }, [cartItems])

    // Add this check for non-card items
    const hasNonCardItems = useMemo(() => {
        return cartItems.some(item => item.type !== 'card');
    }, [cartItems]);

    // Organize items by type
    const organizedItems = useMemo(() => {
        const items = {
            tlds: [],
            slds: [],
            brokerServices: [],
            cards: []
        };

        cartItems.forEach(item => {
            if (item.type === 'TLD') {
                items.tlds.push(item);
            } else if (item.type === 'BROKER_SERVICE') {
                items.brokerServices.push(item);
            } else if (item.type === 'card') {
                items.cards.push(item);
            } else if (item.sld) {
                items.slds.push(item);
            }
        });

        return items;
    }, [cartItems]);

    return (
        <div>

            <Drawer
            lockBackgroundScroll
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                className='bla bla bla !w-[380px] dark:!bg-jacarta-900'
            >
                <div className='flex flex-col justify-between p-4 overflow-y-auto h-full md:max-h-[100vh] max-h-[92vh]'>
                    <div className="flex-1 overflow-y-auto">
                        <div>
                            <div className='flex justify-between items-center'>
                                <div className='flex items-center gap-1'>
                                    <FaCartShopping className='w-4 h-4' />
                                    <p>Cart</p>
                                </div>
                                <MdClose onClick={toggleDrawer} className='cursor-pointer w-6 h-6 ' />
                            </div>

                            {/* Add congratulations message */}
                            {hasNonCardItems && (
                                <div className="mt-4 bg-gradient-to-r from-accent/10 via-accent/15 to-accent/10 rounded-md p-2.5 border border-accent/20">
                                    <div className="flex items-center gap-2">
                                        <div className="w-8 h-8 rounded-full bg-accent/10 flex items-center justify-center flex-shrink-0">
                                            <svg className="w-4 h-4 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium text-jacarta-900 dark:text-white">
                                                Congratulations!
                                            </span>
                                            <span className="text-xs text-jacarta-600 dark:text-jacarta-100 mt-1">
                                                You're one step closer to owning your digital identity
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* {cartItems.length === 0 ? */}
                            {/* <div className='bg-white shadow-lg dark:bg-jacarta-800 rounded-lg p-3 mt-6'>
                                <div className='flex items-center gap-1'>
                                
                                    <p className='text-[16px] flex items-center gap-2'><Image width={32} height={32} src='/icons/matic-white.png' className='w-6 h-6 hidden dark:inline-block' /> <Image src='/icons/matic-dark.png' width={32} height={32} className='w-6 h-6 inline-block dark:hidden' /> Own your identity</p>
                                </div>
                                <p className='text-sm mt-2'>Mint Polygon domains, securely stored in a non-custodial wallet, 100% user-owned ,transferable to your discretion.
                                    <Link onClick={toggleDrawer} href='/learn'><FaQuestionCircle className='ml-1 text-accent inline' /></Link>
                                </p>
                            </div> */}
                 

                            {cartItems.length === 0 ?
                                <div className='flex justify-center items-center my-10 pb-2 h-full '>
                                    <p className='text-sm text-center'>Empty Cart </p>
                                </div> :
                                <>
                                    {/* TLDs Section */}
                                    {organizedItems.tlds.length > 0 && (
                                        <CartSection 
                                            title="TLDs" 
                                            items={organizedItems.tlds} 
                                            onRemove={removeDomain}
                                        />
                                    )}

                                    {/* Broker Services Section */}
                                    {cartItems.some(item => item.type === 'BROKER_SERVICE') && (
                        <div className='mt-6'>
                            <div className='flex items-center justify-between border-b border-jacarta-100 dark:border-jacarta-600 pb-2'>
                                <div className='flex items-center gap-2'>
                                    <p>Broker Services</p>
                                    <div className='w-3 h-3 bg-white shadow-lg dark:bg-jacarta-800 text-xs rounded-full'>
                                        {cartItems.filter(item => item.type === 'BROKER_SERVICE').length}
                                    </div>
                                </div>
                            </div>
                            <div className='my-2 space-y-3'>
                                {cartItems
                                    .filter(item => item.type === 'BROKER_SERVICE')
                                    .map((item) => (
                                        <div 
                                            key={item.id} 
                                            className='relative bg-white dark:bg-jacarta-800 shadow-lg rounded-lg p-3 border border-jacarta-100 dark:border-jacarta-600'
                                        >
                                            <div className='flex justify-between items-start gap-3'>
                                                <div>
                                                    <div className="flex items-center gap-2 mb-1">
                                                        <span className="text-xs px-2 py-0.5 bg-accent/40  dark:bg-accent/10 dark:text-accent rounded-full">
                                                            Broker Service
                                                        </span>
                                                    </div>
                                                    <h4 className="font-display text-base text-jacarta-900 dark:text-white">
                                                        {item.fullname}
                                                    </h4>
                                                    <div className="flex items-center gap-2 mt-1">
                                                        <span className="text-sm text-jacarta-700 dark:text-jacarta-300">
                                                            with {item.metadata.brokerName}
                                                        </span>
                                                        <span className="text-sm font-medium text-jacarta-900 dark:text-accent">
                                                            ${item.price.toLocaleString()}
                                                        </span>
                                                    </div>
                                                </div>
                                                <button 
                                                    onClick={() => removeDomain(item)}
                                                    className="text-jacarta-700 hover:text-red-500 dark:text-jacarta-300 transition-colors"
                                                >
                                                    <BsTrash className='w-4 h-4' />
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>)}

                                    {/* SLDs Section */}
                                    {organizedItems.slds.length > 0 && (
                                        <CartSection 
                                            title="Domains" 
                                            items={organizedItems.slds} 
                                            onRemove={removeDomain}
                                        />
                                    )}

                            
                                </>
                            }
                        </div>
                    </div>

                    <div className='mt-auto w-full'>
                        <div className='border-t dark:border-jacarta-600 pt-4 mt-4'>
                            <CardsSets data={cartItems.find(item => item.type === 'card')} />
                            
                            <div className="mt-3 bg-gradient-to-r from-accent/10 via-accent/15 to-accent/10 dark:from-accent/10 dark:via-accent/20 dark:to-accent/10 rounded-md p-3 border border-accent/20">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <div className="flex -space-x-1.5">
                                            {[1, 2, 3, 4].map((num) => (
                                                <div 
                                                    key={num} 
                                                    className="w-5 h-5 rounded-full bg-white dark:bg-jacarta-700 flex items-center justify-center text-[10px] font-semibold text-jacarta-600 dark:text-accent shadow-sm border border-accent/60 dark:border-accent/40 "
                                                >
                                                    {num}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="text-xs font-medium text-jacarta-900 dark:text-white">
                                                Pay in 4 interest-free installments
                                            </span>
                                            <span className="text-[10px] text-jacarta-600 dark:text-jacarta-300">
                                                ${((totalPrice || 0) / 4).toFixed(2)}/payment
                                            </span>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <div className="bg-accent/20  dark:bg-accent  text-jacarta-900 px-2 py-0.5 rounded-md text-[10px] font-medium animate-pulse min-w-[100px]  text-center ">
                                            COMING SOON
                                        </div>
                                        <div className="absolute -bottom-1 left-0 right-0 h-[2px] bg-gradient-to-r from-transparent via-accent/40 to-transparent"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='flex items-center justify-between mt-4'>
                            <p className='text-base'><span className='text-sm'>{cartItems.length}</span> Items</p>
                            <p className='text-base font-medium'>Subtotal: ${totalPrice ? parseFloat(totalPrice).toFixed(2) : 0}</p>
                        </div>
                        
                        {isLoggedIn?.address ?
                            <button onClick={() => navigateTo('/checkout')} className=" block items-center justify-center rounded-md text-sm lg:text-base font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[18px] px-[32px] md:py-[20px] md:px-[38px] w-full mt-10 cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center">Checkout</button> :
                            <ConnectButton
                                style={{
                                    minWidth: "100%",
                               
                                }}
                                client={client}
                                wallets={wallets}
                                connectModal={{
                                    size: "compact",
                                    title: "Connect Wallet",
                                    showThirdwebBranding: false,
                                    titleIcon: 'https://netzylo-testing.vercel.app/mbl.svg'
                                }}
                                connectButton={{
                                    className: "!flex !items-center !justify-center !rounded-md text-sm !lg:text-lg !font-medium !bg-gradient-to-tr !from-hotPink !via-purplish !to-accent !py-[18px] !px-[32px] !md:py-[20px] !md:px-[38px] !w-full !mt-10 !cursor-pointer !button z-[2] !transition-all !duration-500 !text-white !hover:-translate-y-1 !text-center"
                                }}
                                // onConnect={() => navigateTo('/checkout')}
                                theme={theme === 'dark' || theme === 'system' ? "dark" : "light"}
                            >
                                Buy Now
                            </ConnectButton>

                        }
                    </div>
                </div>
            </Drawer >
        </div >
    )
}

// Helper component for repeated sections
const CartSection = ({ title, items, onRemove }) => (
    <div className='mt-6'>
        <div className='flex items-center justify-between border-b border-jacarta-100 dark:border-jacarta-600 pb-2'>
            <div className='flex items-center gap-2'>
                <p>{title}</p>
                <div className='w-3 h-3 bg-white shadow-lg dark:bg-jacarta-800 text-xs rounded-full'>
                    {items.length}
                </div>
            </div>
        </div>
        <div className='my-6 space-y-3'>
            {items.map((item) => (
                <div key={item.id} className='bg-white shadow-lg dark:bg-jacarta-800 rounded-lg p-3'>
                    <div className='flex items-center justify-between'>
                        <div>
                            {item.sld && (
                                <div className="truncate shrink whitespace-nowrap text-ellipsis font-semibold text-jacarta-900 text-sm lg:text-base dark:text-white mb-0">
                                    {item.sld}
                                </div>
                            )}
                            <span className="text-jacarta-900 dark:text-white font-semibold">
                                {item.tld}
                            </span>
                            <p className='text-xs'>${item.price}</p>
                        </div>
                        <BsTrash 
                            onClick={() => onRemove(item)} 
                            className='w-4 h-4 cursor-pointer' 
                        />
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export default CartDrawer


function CardsSets({ data }) {
    const dispatch = useDispatch();
    const [isEnabled, setIsEnabled] = React.useState(data?.quantity > 0);

    function handleToggle() {
        if (!isEnabled) {
            // Add to cart with quantity 1
            dispatch(addToCart({
                fullname: "Tangem Card (Pack of 2)",
                price: 54.90,
                description: "",
                tld: "card",
                id: "tangem-card",
                type: 'card',
                quantity: 1,
            }));
        } else {
            // Remove from cart
            dispatch(removeItemFromCart({
                fullname: "Tangem Card (Pack of 2)",
                price: 54.90,
                description: "",
                id: "tangem-card",
                type: 'card',
                quantity: 1,
            }));
        }
        setIsEnabled(!isEnabled);
    }

    return (
        <div className="bg-white dark:bg-jacarta-800 rounded-md p-3 shadow-lg">
            <div className="flex items-center justify-between mb-2">
                <div>
                    <h3 className="text-base font-bold text-jacarta-900 dark:text-white">
                        Tangem Hard Wallet
                    </h3>
                    <div className=" text-jacarta-900 dark:text-accent font-bold text-sm mt-0.5">
                        $54.90
                    </div>
                </div>
                {/* Toggle Switch */}
                <div className="flex items-center gap-2">
                    <button
                        onClick={handleToggle}
                        className={`
                            relative inline-flex h-5 w-9 border border-jacarta-700 dark:border-jacarta-300 items-center rounded-full transition-colors duration-300
                            ${isEnabled ? 'bg-accent' : 'bg-jacarta-200 dark:bg-jacarta-600'}
                        `}
                    >
                        <span
                            className={`
                                inline-block h-4 w-4 transform rounded-full border border-jacarta-700 dark:border-jacarta-300 bg-white transition-transform duration-300
                                ${isEnabled ? 'translate-x-[17px]' : 'translate-x-[2px]'}
                            `}
                        />
                    </button>
                </div>
            </div>

            <div className="flex gap-3">
                <div className="w-20 h-20 flex-shrink-0 bg-jacarta-50 dark:bg-jacarta-700 rounded-md p-1.5">
                    <Image 
                        width={400} 
                        height={400} 
                        src='/images/tangam.png' 
                        className='rounded-md w-full h-full object-contain'
                        alt="Tangem Wallet"
                    />
                </div>

                <div className="flex-grow">
                    <div className="text-xs text-jacarta-700 dark:text-jacarta-200 mb-2">
                        Protect your digital assets with military-grade security. Includes 2 premium NFC cards.
                    </div>

                    <div className="bg-jacarta-50 dark:bg-jacarta-700 p-2 rounded-md mb-2">
                        <ul className="text-xs space-y-1.5 text-jacarta-700 dark:text-jacarta-200">
                            <li className="flex items-center gap-1.5">
                                <svg className="w-3.5 h-3.5 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                </svg>
                                Military-grade encryption
                            </li>
                            <li className="flex items-center gap-1.5">
                                <svg className="w-3.5 h-3.5 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                </svg>
                                Dual card backup system
                            </li>
                            <li className="flex items-center gap-1.5">
                                <svg className="w-3.5 h-3.5 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                </svg>
                                Simple tap-to-use interface
                            </li>
                        </ul>
                    </div>

                    {/* Quantity selector - only show when toggle is enabled */}
                    {isEnabled && (
                        <div className="flex items-center gap-3">
                            <div className="text-xs text-jacarta-700 dark:text-jacarta-200">
                                Quantity:
                            </div>
                            <div className="flex items-center gap-1 bg-jacarta-100 dark:bg-jacarta-600 rounded-md p-0.5">
                                <button 
                                    className="w-6 h-6 flex items-center justify-center rounded-md hover:bg-jacarta-200 dark:hover:bg-jacarta-700 transition-colors text-jacarta-700 dark:text-white"
                                    onClick={() => dispatch(changeQuantity({ action: "-", id: data?.id }))}
                                >
                                    -
                                </button>
                                <span className="w-6 text-center text-sm text-jacarta-700 dark:text-white">
                                    {data?.quantity || 1}
                                </span>
                                <button 
                                    className="w-6 h-6 flex items-center justify-center rounded-md hover:bg-jacarta-200 dark:hover:bg-jacarta-700 transition-colors text-jacarta-700 dark:text-white"
                                    onClick={() => dispatch(changeQuantity({ action: "+", id: data?.id }))}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}