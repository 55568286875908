
import { useActiveAccount } from "thirdweb/react";
import { useEffect } from 'react';
import { useAuthRedirect } from './hooks/useAuthRedirect';

export function withProtectedRoute(WrappedComponent) {
  return function WithProtectedRoute(props) {

    const account = useActiveAccount();
    const { redirectToSignIn } = useAuthRedirect();
    
    useEffect(() => {
      if (!account) {
        redirectToSignIn();
      }
    }, [account, redirectToSignIn]);

    if (!account) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}