import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { manageModalhide, walletModalShow } from "../../redux/counterSlice";
import { FaAd, FaBlog, FaCartPlus, FaCommentAlt, FaSignOutAlt, FaDiscord, FaSignInAlt, FaFacebookF, FaInstagram, FaLinkedinIn, FaWallet, FaHome, FaCube } from "react-icons/fa";
import { BsWindowStack } from 'react-icons/bs'
import { FaStar } from "react-icons/fa";

import { useRouter } from "next/router";

import SearchBar from "../hero/Searchbar";
import Link from "next/link";
import { BsCardList } from 'react-icons/bs'
import { IoReceiptOutline } from 'react-icons/io5';
import { AiOutlineSetting } from 'react-icons/ai'
import { FiCreditCard } from 'react-icons/fi'
import { GoSignIn } from 'react-icons/go'
import DarkMode from "../mode/DarkMode";

const Manage_modal = () => {

  const manageModal = useSelector((state) => state.counter.manageModal);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false)
  const [resources, setResources] = React.useState(false)

  const router = useRouter()


  async function navigateTo(path) {
    await router.push(path)
    dispatch(manageModalhide())

  }

  return (
    <div className="">
      {/* <!-- Wallet Modal --> */}
      <div
        className={manageModal ? "block modal fade show p-4 fixed z-[999999]  " : "modal fade hidden"}
      >
        <div className="modal-dialog max-w-lg w-full gradient-box ">
          <div className="modal-content   dark:bg-jacarta-900  overflow-auto m-0  rounded-md opacity-100 right-0">
            <div className="modal-header">
              <h5 className="modal-title" id="manageModalLabel">
                {/* Choose your wallet */}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(manageModalhide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-900 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6 ">

              <SearchBar className='z-[2]' />

        
              <div
                onClick={() => navigateTo('/')}
                className="dropdown-toggle font-display dark:text-accent text-jacarta-900 cursor-pointer  flex items-center justify-between py-3.5 text-base  px-5 w-full border border-accent  bg-accent/10 rounded-md mt-2"
              >
                <span className="flex gap-2 items-center"><BsCardList className="mb-1 " />  <span
                  className={
                    resources
                      ?
                      "text-jacarta-900 dark:text-accent"
                      : ""
                  }
                >

                  My Domains
                </span>
                </span>

              </div>

              <div
                onClick={() => navigateTo('/')}
                className="dropdown-toggle font-display dark:text-accent text-jacarta-900 cursor-pointer  flex items-center justify-between py-3.5 text-base  px-5 w-full border border-accent  bg-accent/10 rounded-md mt-2"
              >
                <span className="flex gap-2 items-center"><IoReceiptOutline className="mb-1 " />  <span
                  className={
                    resources
                      ?
                      "text-jacarta-900 dark:text-accent"
                      : ""
                  }
                >

                  Transactions
                </span>
                </span>

              </div>

              <div
                onClick={() => navigateTo('/')}
                className="dropdown-toggle font-display dark:text-accent text-jacarta-900 cursor-pointer  flex items-center justify-between py-3.5 text-base  px-5 w-full border border-accent  bg-accent/10 rounded-md mt-2"
              >
                <span className="flex gap-2 items-center"><AiOutlineSetting className="mb-1 " />  <span
                  className={
                    resources
                      ?
                      "text-jacarta-900 dark:text-accent"
                      : ""
                  }
                >

                  Account settings
                </span>
                </span>

              </div>

         
              <div className="grid grid-cols-2 md:grid-cols-2 gap-4 my-6 ">
                <div className="flex items-center  p-3 py-2 rounded-md transition-all duration-150  cursor-pointer border-[1px] border-[#00000014] hover:bg-accent/20 dark:border-[#ffffff14]  w-full gap-2  " onClick={() => dispatch(walletModalShow())}>
                  <div className=" rounded-full ">
                    <FaWallet className="text-accent" />
                  </div>
                  <span className="text-jacarta-900 dark:text-white">Wallet</span>
                </div>

                <div className="flex items-center  p-3 py-2 rounded-md transition-all duration-150  cursor-pointer border-[1px] border-[#00000014] hover:bg-accent/20 dark:border-[#ffffff14]  w-full gap-2  ">
                  <div className="  rounded-full">
                    <FaCartPlus className="text-accent" />
                  </div> <span className="text-jacarta-900 dark:text-white">Cart </span>
                </div>

               

                <DarkMode />
              </div>



              <div className="flex justify-center gap-6 mt-[30px] mb-[20px]">
                <a href="https://discord.com/channels/netzylo/" target="_blank" >
                  <FaDiscord className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all duration-500  hover:-translate-y-1" />
                </a>
                <a href="https://www.facebook.com/NetZyloInc/" target="_blank" >
                  <FaFacebookF className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all duration-500  hover:-translate-y-1" />
                </a>
                <a href="https://twitter.com/NetZylo/" target="_blank">
                  <svg viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:fill-gray-300 transition-all  duration-500  hover:-translate-y-1 fill-white"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>

                </a>
                <a href="https://instagram.com/netzylo/" target="_blank">
                  <FaInstagram className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all  duration-500  hover:-translate-y-1" />
                </a>
                <a href="https://www.linkedin.com/company/netzylo/" target="_blank">
                  <FaLinkedinIn className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all  duration-500  hover:-translate-y-1" />
                </a>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div >
  );
};

export default Manage_modal;
