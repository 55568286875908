import Footer from "./footer";
import { useRouter } from "next/router";
import Header04 from "./header/Header04";
import { Toaster } from "react-hot-toast";
import { useTheme } from "next-themes";
import { useDispatch } from "react-redux";
import Coming_Soon_Modal from "./modal/coming_soon_Modal";
import React, { useEffect } from "react";
import { comingSoonModalShow } from "../redux/counterSlice";
import Manage_modal from "./modal/Manage_Modal";
import DashboardLayout from "../layouts/DashboardLayout";
import Buy_Domains_Modal from "./modal/Buy_Domains_Modal";
import Netzylo_Modal from "./modal/Netzylo_modal";
import CartDrawer from "./cartDrawer";
import { useActiveAccount } from "thirdweb/react";
import { setUser } from "../redux/authSlice";
import useProfile from "../hooks/queryHooks/getUser";
import Meta from "./Meta";
import SimpleLayout from "../layouts/SimpleLayout";
import CommunityLayout from "../layouts/CommunityLayout";
import SocialShare from "./modal/SocialShare";
import Header03 from "./header/Header03";

export default function Layout({ children }) {
  const route = useRouter();
  const emailWalletAddress = useActiveAccount();
  const dispatch = useDispatch();

  // header start
  let header;
  if (route.asPath.startsWith("/discover")) {
    header = <Header03 />;
  } else {
    header = <Header04 />;
  }

  const darkTheme = {
    border: "2px solid #02f6d1",
    background: "#101436",
    color: "#fff",
  };

  const lightTheme = {
    border: "2px solid #02f6d1",
    background: "#fff",
    color: "#101436",
  };
  const { theme } = useTheme();

  React.useEffect(() => {
    const visitedBefore = localStorage.getItem("visitedBefore");
    setTimeout(() => {
      if (!visitedBefore) {
        dispatch(comingSoonModalShow());
      }
    }, 15000);
  }, []);

  const getLayout = () => {
    if (route.pathname.startsWith("/dashboard/community")) {
      return CommunityLayout;
    } else if (route.pathname.startsWith("/dashboard")) {
      return DashboardLayout;
    } else {
      return SimpleLayout;
    }
  };

  const { data: userData } = useProfile(emailWalletAddress);

  useEffect(() => {
    if (userData) {

      dispatch(setUser(userData));
    }
  }, [userData]);

  const Layout = getLayout();

  return (
    <>
      <Meta />
      <Toaster
        toastOptions={{
          duration: 2000,
          style:
            theme === "dark" || theme === "system"
              ? { ...darkTheme }
              : { ...lightTheme },
        }}
        position="top-center"
        reverseOrder={false}
      />
      {header}
      <Manage_modal />
      <Coming_Soon_Modal />
      <Buy_Domains_Modal />
      <SocialShare />
      <Netzylo_Modal />
      <CartDrawer />
      <Layout>{children}</Layout>
      <Footer />
    </>
  );
}
