
import { useQuery } from 'react-query';
import api from '../../utils/axios';

const fetchUser = async (address) => {
	const apiUrl = `/api/auth/user/get-user`; 

	const response = await api.get(apiUrl);
	return response.data;
};

const useProfile = (address) => {
	return useQuery(['user-profile',address], ()=>fetchUser(address), {
		enabled: Boolean(address), 
		cacheTime: 0,
        staleTime: 0,
		refetchOnMount: 'always',
	});
};

export default useProfile;
