// lib/apolloClient.js

import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
	uri: process.env.WORDPRESS_GRAPHQL_ENDPOINT, // Replace with your WP GraphQL API endpoint
	cache: new InMemoryCache({
		typePolicies: {
			RootQuery: {
				queryType: true,
			},
			RootMutation: {
				mutationType: true,
			},
		},
	}),
});

export default client;


