'use client';

import { useWalletBalance, useActiveAccount } from "thirdweb/react";
import { createThirdwebClient } from "thirdweb";
import { mainnet } from "thirdweb/chains";
import { useState, useEffect } from 'react';

export const useWalletBalanceV5 = (chainId = mainnet) => {
  const account = useActiveAccount();
  const [formattedBalance, setFormattedBalance] = useState({
    value: "0",
    displayValue: "0.000",
    symbol: "ETH",
    decimals: 18,
  });

  // Initialize Thirdweb client
  const client = createThirdwebClient({
    clientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID,
  });

  const { 
    data: balanceData, 
    isLoading, 
    isError,
    error,
    refetch 
  } = useWalletBalance({
    chain: chainId,
    address: account?.address,
    client,
  });

  useEffect(() => {
    if (balanceData && !isLoading && !isError) {
      setFormattedBalance({
        value: balanceData.value || "0",
        displayValue: balanceData.displayValue || "0.000",
        symbol: balanceData.symbol || "ETH",
        decimals: balanceData.decimals || 18,
      });
    }
  }, [balanceData, isLoading, isError]);

  const getFormattedBalance = (decimals = 3) => {
    if (isLoading || isError || !balanceData) return "0.000";
    return Number(formattedBalance.displayValue).toFixed(decimals);
  };

  return {
    balance: formattedBalance,
    isLoading,
    isError,
    error,
    refetch,
    getFormattedBalance,
    displayValue: formattedBalance.displayValue,
    symbol: formattedBalance.symbol,
    value: formattedBalance.value,
    decimals: formattedBalance.decimals,
    account,
    isConnected: !!account,
  };
}; 