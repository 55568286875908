import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { buyDomainsModalhide, removeItemFromCart } from "../../redux/cartSlice";
import { BsCheckCircleFill } from 'react-icons/bs'
import Image from "next/image";
import Link from "next/link";
import { BiWalletAlt } from "react-icons/bi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Renewel_Modal from "./Renewel_Modal";
import Netzylo_Modal from "./Netzylo_modal";
import { renewalModalShow, walletAddonShow } from "../../redux/counterSlice";

const Buy_Domains_Modal = () => {
	const { cartItems, buyDomainsModal } = useSelector((state) => state.cart);
	const dispatch = useDispatch();
	const totalPrice = useMemo(() => {
		if (cartItems.length > 0) {
			return cartItems.reduce((acc, curr) => {
				// Extracting numeric value from the price string
				const price = curr.price ? parseFloat(curr.price) : 0;
				return acc + price;
			}, 0);
		}
	}, [cartItems]);

	function removeDomain(item) {
		dispatch(removeItemFromCart(item))
	}




	return (
		<div className="">
			{/* <!-- Wallet Modal --> */}
			<div
				className={buyDomainsModal ? "block modal fade show p-4 z-[99999] " : "modal fade hidden"}
			>
				<div className="modal-dialog max-w-lg w-full  m-auto">
					<div className="modal-content gradient-box  dark:bg-jacarta-900">
						<div className="modal-header border border-b-gray-300 dark:border-b-gray-200">
							<h5 className="modal-title" id="buyDomainsModalLabel">
								Buy Domains
							</h5>

							<button
								type="button"
								className="btn-close"
								onClick={() => dispatch(buyDomainsModalhide())}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="24"
									height="24"
									className="fill-jacarta-900 h-6 w-6 dark:fill-white"
								>
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
								</svg>
							</button>
						</div>

						{/* <!-- Body --> */}
						<div className="modal-body text-center space-y-2  p-4">
							{cartItems.map((item) => {

								return (
									<div className="flex justify-between items-center pb-3 gap-8 border border-b-gray-300 dark:border-b-gray-200 border-x-0 border-t-0">
										<div className="flex items-center gap-2">
											<BsCheckCircleFill className='text-green' />
											<div>
												{item.name && <div className="truncate shrink whitespace-nowrap text-sm lg:text-base text-ellipsis font-semibold text-jacarta-900 dark:text-white">{item.name}</div>}
												<span className="text-jacarta-900 dark:text-white font-semibold">{item.type === 'ai' ? item.domain : `${item.tld ? "." : ""}${item.tld ? item.tld : ""}`}</span>
											</div>

											<span className=' hidden md:block  font-semibold transition-all bg-jacarta-800 text-white text-sm p-2  rounded-md' >
												TLD
											</span>
										</div>

										<div className="flex items-center">

											<div className="flex items-center flex-col gap-2">
												<div className="text-xs  font-semibold lg:text-sm text-jacarta-900 dark:text-white">${item.price}</div>

												<p className=' cursor-pointer text-jacarta-900 dark:text-white text-xs lg:text-sm  bg-clip-text text-transparent' onClick={() => removeDomain(item)}>Remove</p>
											</div>

										</div>

									</div>


								)
							})}

							<section className="flex flex-col text-jacarta-900 dark:text-white mt-6 relative">
								<div className="flex justify-end items-end my-3">
									{/* <div className="flex items-center gap-2">
										<input type="checkbox" checked={true} disabled={true} />
										<label htmlFor="own-forever" className="text-jacarta-900 dark:text-white text-sm lg:text-base font-medium">Own Forever</label>
									</div> */}
									<div className="flex items-center gap-2">
										<p className="text-accent-content text-sm lg:text-base ">no renewals ever</p>
										<AiOutlineQuestionCircle onClick={() => dispatch(renewalModalShow())} className="text-accent w-4 md:w-6 h-4 md:h-6 cursor-pointer" />


									</div>

								</div>

								<div className="flex justify-between items-center my-3">
									<div className="flex items-center gap-2">
										<BiWalletAlt className="text-accent w-4 md:w-6 h-4 md:h-6" />
										<span className="text-jacarta-900 dark:text-white text-sm lg:text-base  font-medium">Netzylo Wallet</span>
									</div>
									<div className="flex items-center gap-1">
										<p className="text-accent-content text-sm lg:text-base  ">$5/yr</p>
										<HiOutlineDotsVertical
											onClick={() => dispatch(walletAddonShow())} className="text-accent w-4 md:w-6 h-4 md:h-6 cursor-pointer" />

									</div>
								</div>

								<div className="flex justify-between items-center py-2 lg:py-3 text-sm">
									<p className="text-base lg:text-lg">Total <span className="text-sm lg:text-base text-grey-600">{cartItems.length} domains</span>
									</p>
									<div className="text-right">

										<p className="text-accent-content text-base lg:text-lg">${totalPrice}</p>
									</div>
								</div>
								<Link href="/checkout" onClick={() => dispatch(buyDomainsModalhide())} className="items-center mt-4 justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[18px] px-[32px] md:py-[20px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center"
								>Continue</Link>

							</section>
						</div>
						{/* <!-- end body --> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Buy_Domains_Modal;
