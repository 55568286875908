import React from "react";
import Sidebar from "../components/Sidebar";
import withProtectedRoute from "../withProtectedRoute";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { TbBrandMonday } from "react-icons/tb";
import { FaThemeisle } from "react-icons/fa";
import { MdManageAccounts , MdOutlineRocketLaunch , MdOutlineProductionQuantityLimits} from "react-icons/md";
import { IoAnalytics } from "react-icons/io5";

const CommunityLayout = ({ children }) => {

  const routes = [
    {
      icon: TbBrandMonday,
      name: "Dashboard",
      path: "/dashboard/community",
    },
    {
      icon: FaThemeisle,
      name: "Theme",
      path: "/dashboard/community/theme",
    },
    {
      icon: MdManageAccounts,
      name: "Users Management",
      path: "/dashboard/community/userManagment",
    },
    {
      icon: MdOutlineRocketLaunch,
      name: "NFT Launchpad",
      path: "/dashboard/community/launchpad",
    },
    {
      icon: MdOutlineProductionQuantityLimits,
      name: "Products",
      path: "/dashboard/community/products",
    },

    {
      icon: AiOutlineDollarCircle,
      name: "Revenue",
      path: "/dashboard/community/revenue"
    },

    {
      icon: IoAnalytics,
      name: "Analytics",
      path: "/dashboard/community/analytics",
    },
  
  ];

  return (
    <div className=" relative flex flex-col md:flex-row gap-2 mt-36 ">
      <Sidebar routes={routes} />
      <div className="flex-grow h-full dark:bg-jacarta-900">
        <main className="px-4 md:px-8">{children}</main>
      </div>
    </div>
  );
};

export default CommunityLayout;
